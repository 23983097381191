import { Container } from "react-bootstrap"
import Head from "next/head"
import Router from "next/router"
import { StandardLayout } from "layouts"

import { HeroBannerModel } from "@components/HeroBanner/HeroBanner.model"
import { LatestArticleModel } from "@components/LatestArticle/LatestArticle.model"

import { BASE_PATH } from "@shared/services/HttpServices"
import HomeServices from "@shared/services/HomeServices"
import SharedServices, { MetaModel } from "@shared/services/SharedServices"

import {
  HeroBanner,
  InvestmentPropertyList,
  InvestmentCoverageArea,
  HasPartners,
  LatestArticle,
  RecommendedVideo,
} from "components"
import { RecommendedVideoModel } from "@components/RecommendedVideo/RecommendedVideo.model"
import { HASPartnerMasterlistModel } from "@components/HASPartnerMasterlist/HASPartnerMasterlist.model"
import {
  InvestmentPropertyListModel,
  InvestmentPropertyModel,
} from "@components/InvestmentPropertyList/InvestmentPropertyList.model"
import InvestmentPropertyServices from "@shared/services/InvestmentPropertyServices"
import { onClickHASDetail } from "lib/HasPartner/utils"
import { OnPropertyClick } from "lib/InvestmentProperty/utils"
import { OnMarketIntelClick } from "lib/MarketIntelligence/util"

const Home = ({
  meta,
  heroBanner,
  hostastayPartners,
  latestArticle,
  recommendedVideo,
  investmentProperty,
  footer,
  error,
  coverageData,
}: {
  meta: MetaModel
  heroBanner: HeroBannerModel
  hostastayPartners: HASPartnerMasterlistModel
  latestArticle: LatestArticleModel
  recommendedVideo: RecommendedVideoModel
  investmentProperty: InvestmentPropertyListModel
  footer?: any
  error?: any
  coverageData?: any[]
}) => {
  if (error) {
    return <div>An error occured: {error.message}</div>
  }

  const handleIPLCtaClick = () => {
    Router.push(`/investment-property`)
  }

  const handleHPCtaClick = () => {
    Router.push(`/has-partner`)
  }

  return (
    <StandardLayout footerData={footer}>
      <Head>
        <title>{meta.title}</title>
        <meta name="description" content={meta.desktop_description} />
        <meta name="keywords" content={meta.keyword} />
      </Head>
      <Container fluid className="g-0">
        <HeroBanner
          imageUrl={`${BASE_PATH}${heroBanner.imageUrl}`}
          imageAlt={heroBanner.imageAlt}
          title={heroBanner.title}
          description={heroBanner.description}
        />
      </Container>
      <Container className="py-5">
        <InvestmentPropertyList
          dataSource={investmentProperty}
          onCardClick={OnPropertyClick}
          onClick={handleIPLCtaClick}
        />
      </Container>
      <div className="bg-primary py-5">
        <Container>
          <InvestmentCoverageArea coverageData={coverageData} />
        </Container>
      </div>
      <div className="bg-grey-bright py-5">
        <Container>
          <HasPartners
            dataSource={hostastayPartners}
            onClick={handleHPCtaClick}
            onCardClick={onClickHASDetail}
          />
        </Container>
      </div>
      <Container className="py-5">
        <LatestArticle
          dataSource={latestArticle}
          handleCardClick={OnMarketIntelClick}
        />
      </Container>
      <div className="bg-grey-bright pt-5" style={{ paddingBottom: "7rem" }}>
        <Container>
          <RecommendedVideo dataSource={recommendedVideo} />
        </Container>
      </div>
    </StandardLayout>
  )
}

export async function getServerSideProps(ctx) {
  try {
    const {
      meta,
      heroBanner,
      hostastayPartners,
      latestArticle,
      recommendedVideo,
      investmentProperty,
      coverageData,
    } = await HomeServices().getPageProperties()
    const { propertyList } =
      await InvestmentPropertyServices().getTorusPropertyList({
        include: "images",
        search: {},
        pagination: "8",
      })
    investmentProperty.properties = propertyList.map((data, idx) => {
      if (idx < 8) {
        let res: InvestmentPropertyModel = {
          id: data.id,
          imageUrl: data.imageUrl,
          imageAlt: data.imageAlt,
          title: data.name,
          description: data.location,
          vp_date: data.vp_date,
          market_roi: data.market_roi,
        }
        return res
      }
    })
    const footer = await SharedServices().getFooter()
    return {
      props: {
        meta,
        heroBanner,
        hostastayPartners,
        latestArticle,
        recommendedVideo,
        investmentProperty,
        coverageData,
        footer,
      },
    }
  } catch (error) {
    console.log(error)
    return { props: { error } }
  }
}

export default Home
